<template>
	<el-form
		:model="dynamicValidateForm"
		ref="dynamicValidateForm"
		label-width="100px"
		class="demo-dynamic"
	>
		<el-form-item prop="game" label="比赛名称">
			<el-select
				v-model="dynamicValidateForm.game"
				filterable
				placeholder="请选择比赛"
			>
				<el-option
					v-for="item in gameList"
					:key="item.gameid"
					:label="item.gamename"
					:value="item.gameid"
				/>
			</el-select>
		</el-form-item>
		<el-form-item prop="champion" label="赛事总冠军">
			<el-select
				v-model="dynamicValidateForm.champion"
				filterable
				placeholder="请选择总冠军院系"
			>
				<el-option
					v-for="item in deptList"
					:key="item.deptid"
					:label="item.deptname"
					:value="item.deptid"
				/>
			</el-select>
		</el-form-item>
		<p>甲组</p>

		<el-form-item
			v-for="(row, index) in dynamicValidateForm.info1"
			:label="'第' + (index + 1) + '行'"
			:key="row.key"
		>
			<el-select v-model="row.deptid" filterable placeholder="院系"
				style="width: 10%; margin-right: 2%">
				<el-option
					v-for="item in deptList1"
					:key="item.deptid"
					:label="item.deptname"
					:value="item.deptid"
				>
				</el-option>
			</el-select>
			<el-input
				v-model="row.ranking"
				placeholder="名次（数字）"
				style="width: 10%; margin-right: 2%"
			/>
			<el-input
				v-model="row.names"
				placeholder="请输入参赛选手姓名（用中文逗号分隔）"
				style="width: 40%; margin-right: 2%"
			/>
			<el-button @click.prevent="removerow1(row)" type="danger"
				>删除此行</el-button
			>
		</el-form-item>
		<el-form-item>
			<el-button class="add-button" @click="addrow1" type="success"
				>新增名次</el-button
			>
		</el-form-item>

		<p>乙组</p>
		<el-form-item
			v-for="(row, index) in dynamicValidateForm.info2"
			:label="'第' + (index + 1) + '行'"
			:key="row.key"
		>
			<el-select v-model="row.deptid" filterable placeholder="院系"
				style="width: 10%; margin-right: 2%">
				<el-option
					v-for="item in deptList2"
					:key="item.deptid"
					:label="item.deptname"
					:value="item.deptid"
				>
				</el-option>
			</el-select>
			<el-input
				v-model="row.ranking"
				placeholder="名次（数字）"
				style="width: 10%; margin-right: 2%"
			/>
			<el-input
				v-model="row.names"
				placeholder="请输入参赛选手姓名（用中文逗号分隔）"
				style="width: 40%; margin-right: 2%"
			/>
			<el-button @click.prevent="removerow2(row)" type="danger"
				>删除此行</el-button
			>
		</el-form-item>
		<el-form-item>
			<el-button class="add-button" @click="addrow2" type="success"
				>新增名次</el-button
			>
		</el-form-item>

		<el-button type="primary" @click="submitForm('dynamicValidateForm')"
			>提交</el-button
		>
	</el-form>
</template>
<style scoped>
</style>
<script>
import axios from "axios";
import qs from "qs";
export default {
	name: "SubmitResult",
	data() {
		return {
			deptList: "",
			deptList1: [],
			deptList2: [],
			gameList: "",
			dynamicValidateForm: {
				info1: [
					{
						deptid: "",
						ranking: "",
						names: "",
					},
				],
				info2: [
					{
						deptid: "",
						ranking: "",
						names: "",
					},
				],
				game: "",
				champion: "",
			},
		};
	},
	beforeMount() {
		if (localStorage.getItem("token")) {
			axios.defaults.headers.common["Authorization"] = localStorage.getItem(
				"token"
			);
		}
		axios({
			url: "/api/hello-user",
			method: "GET",
			headers: {
				Authorization: localStorage.getItem("token"),
			},
		})
			.then((res) => {
				// get game list
				this.userid = res.data.userid;
				axios({
					url: "/api/admin/game/list",
					method: "GET",
					headers: {
						Authorization: localStorage.getItem("token"),
					},
				})
					.then((res) => {
						for (let item of res.data.data)
							if (item.gameclub === this.userid) this.tableData.append(item);
						this.gameList = res.data.data;
					})
					.catch((err) => {
						this.messageBox(
							"获取比赛列表失败，" + err?.response?.data?.Message ?? "网络断开或神秘错误",
							"error"
						);
						console.log(err.response);
					});
			})
			.catch((err) => {
				this.messageBox(
					"获取身份信息失败，" + err?.response?.data?.Message ?? "网络断开或神秘错误",
					"error"
				);
				console.log(err.response);
			});
		// get dept list
		axios({
			url: "/api/admin/asso/get?type=dept",
			method: "GET",
			headers: {
				"content-type": "application/x-www-form-urlencoded",
			},
		})
			.then((res) => {
				this.deptList = res.data.data;
				this.deptList1 = [];
				this.deptList2 = [];
				for (let item of res.data.data) {
					if (item.deptgroup === "甲") {
						this.deptList1.push(item);
					} else {
						this.deptList2.push(item);
					}
				}
			})
			.catch((err) => {
				this.messageBox(
					"获取协会列表失败，" + err?.response?.data?.Message ?? "网络断开或神秘错误",
					"error"
				);
				console.log("Get deptList Failed");
				console.log(err.response);
			});
	},
	methods: {
		messageBox(msg, type) {
			this.$message[type](msg);
		},
		submitForm() {
			// console.log(this.dynamicValidateForm.game);
			// console.log(formName);
			if (!this.dynamicValidateForm.game) {
				this.messageBox("请选择一项比赛", "error");
				return false;
			}
			if (!this.dynamicValidateForm.champion) {
				this.messageBox("请选择总冠军院系", "error");
				return false;
			}
			for (let i of this.dynamicValidateForm.info1) {
				if (i.deptid != parseInt(i.deptid)) {
					this.messageBox("请删除不用的空行", "error");
					return false;
				}
				if (i.ranking != parseInt(i.ranking)) {
					this.messageBox("请输入整数作为排名", "error");
					return false;
				}
				if (i.names.length > 200) {
					this.messageBox("姓名过长，请缩减", "error");
					return false;
				}
			}
			for (let i of this.dynamicValidateForm.info2) {
				if (i.deptid != parseInt(i.deptid)) {
					this.messageBox("请删除不用的空行", "error");
					return false;
				}
				if (i.ranking != parseInt(i.ranking)) {
					this.messageBox("请输入整数作为排名", "error");
					return false;
				}
				if (i.names.length > 200) {
					this.messageBox("姓名过长，请缩减", "error");
					return false;
				}
			}
			axios({
				url: "/api/club/result/add",
				method: "POST",
				headers: {
					Authorization: localStorage.getItem("token"),
				},
				data: qs.stringify({
					game: this.dynamicValidateForm.game,
					champion: this.dynamicValidateForm.champion,
					info1: JSON.stringify(this.dynamicValidateForm.info1),
					info2: JSON.stringify(this.dynamicValidateForm.info2),
					fileid: -1,
				}),
			})
				.then(() => {
					// console.log(res);
					this.messageBox("提交成功", "success");
				})
				.catch((err) => {
					this.messageBox(
						"提交失败，" + err?.response?.data?.Message ?? "网络断开或神秘错误",
						"error"
					);
					console.log("Submit Result Failed");
					console.log(err.response);
				});
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
		removerow1(item) {
			var index = this.dynamicValidateForm.info1.indexOf(item);
			if (index !== -1) {
				this.dynamicValidateForm.info1.splice(index, 1);
			}
		},
		addrow1() {
			this.dynamicValidateForm.info1.push({});
		},
		removerow2(item) {
			var index = this.dynamicValidateForm.info2.indexOf(item);
			if (index !== -1) {
				this.dynamicValidateForm.info2.splice(index, 1);
			}
		},
		addrow2() {
			this.dynamicValidateForm.info2.push({});
		},
	},
};
</script>
